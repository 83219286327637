import {
  Box,
} from "../../../components/StyledComponent";
import {
  faLink,
  faExternalLink
} from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faSpotify,
  faApple,
  faAmazon
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import LOGO from "../../../assets/images/cobra-cover_v2.jpg";
import BG_IMG from "../../../assets/images/cobra-cover_background.jpg";
import TIDAL from "../../../assets/images/tidal.png";
// import { isMobile } from "../../../constants";

const Showcase = () => {
  
  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden"
    >
      <Box
        className={`z-30 flex flex-col justify-center items-center h-full w-full bg-contain py-10`}
        style={{backgroundImage: `url(${BG_IMG})`}}
      >
        <img src={LOGO} className="h-[50%] md:h-[55%] md:flex-[1_1_55%] -mb-8 md:mb-1" />
        <div className="font-micro-bold text-[1.45em] md:text-[2em] uppercase text-center">stream cobra on</div>
        <div className="grid md:flex justify-center items-center my-3 w-full">
          <button className="bg-[#ff0100] rounded-3xl py-1 px-6 md:px-3 font-bold text-md md:text-lg my-1 mx-2 flex justify-center items-center" onClick={() => {window.open('https://www.youtube.com/watch?v=DOZNRoL0310')}}><FontAwesomeIcon icon={faYoutube as any} className="mr-1" /> YouTube</button>
          <button className="bg-[#1ed760] rounded-3xl py-1 px-6 md:px-3 font-bold text-md md:text-lg my-1 mx-2 flex justify-center items-center" onClick={() => {window.open('https://open.spotify.com/album/08VJzxuxr4leLd9YhJNlQl')}}><FontAwesomeIcon icon={faSpotify as any} className="mr-1" /> Spotify</button>
          <button className="bg-[#ff2e56] rounded-3xl py-1 px-6 md:px-3 font-bold text-md md:text-lg my-1 mx-2 flex justify-center items-center" onClick={() => {window.open('https://music.apple.com/us/album/cobra-single/1714895312')}}><FontAwesomeIcon icon={faApple as any} className="mr-1" /> Apple Music</button>
          <button className="bg-[#000] text-white border-white border rounded-3xl py-1 px-6 md:px-3 font-bold text-md md:text-lg my-1 mx-2 uppercase flex justify-center items-center" onClick={() => {window.open('https://listen.tidal.com/album/326363836')}}><img src={TIDAL} className="h-[1em] invert mr-1" /> Tidal</button>
          <button className="bg-[#4004f1] rounded-3xl py-1 px-6 md:px-3 font-bold text-md md:text-lg my-1 mx-2 flex justify-center items-center" onClick={() => {window.open('https://music.amazon.com/albums/B0CMG4X8RV')}}><FontAwesomeIcon icon={faAmazon as any} className="mr-1 " /> Amazon Music</button>
        </div>
        <div className="font-micro-bold text-[1.45em] md:text-[2em] uppercase text-center"><a href="https://store.megantheestallion.com/" target="_blank" rel="noreferrer">megan thee stallion merch store <FontAwesomeIcon icon={faExternalLink as any} className="m-auto ml-2 font-normal" /></a></div>
      </Box>
    </Box>
  );
};

export default Showcase;
